<template>
    <div>
        <el-card shadow="never">
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>商品详情页管理</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                <el-input v-model="keyword" placeholder="请输入标题/标签名称" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-select v-model="activeposition" placeholder="请选择活动位置" style="margin:0 10px;width:150px;" size="small">
                                    <el-option label="商品页服务" value="1"></el-option>
                                    <el-option label="平台活动" value="2"></el-option>
                                    <el-option label="服务保障" value="3"></el-option>
                                </el-select>
                                <el-select v-model="enabled" placeholder="请选择起用状态" style="margin:0 10px;width:150px;" size="small">
                                    <el-option label="启用" value="1"></el-option>
                                    <el-option label="停用" value="2"></el-option>
                                </el-select>
                                <el-select v-model="jumptype" placeholder="请选择跳转类型" style="margin:0 10px;width:150px;" size="small">
                                    <el-option label="不跳转" value="1"></el-option>
                                    <el-option label="页面跳转" value="2"></el-option>
                                    <el-option label="商品" value="3"></el-option>
                                    <el-option label="商品集合页" value="4"></el-option>
                                    <el-option label="活动页" value="5"></el-option>
                                    <el-option label="链接" value="6"></el-option>
                                </el-select>
                                <el-button type="primary" @click="search" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="reset" size="small">重置</el-button>
                                <el-button type="danger" @click="add" size="small">添加</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0"
                                v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                :default-sort = "{prop: 'AddDTime', order: 'descending'}"
                                @selection-change="CheckSelection"
                                size="small">
                    <el-table-column label="标题" prop="PdtCode">
                        <template #default='scope'>
                            <span>{{scope.row.PdtCode}}:{{scope.row.ID}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="标题活动标签" prop="PdtName"></el-table-column>
                    
                    <el-table-column label="图片文件" width="120" prop="Category"></el-table-column>
                    <el-table-column label="详情页位置" width="120" prop="Brand"></el-table-column>
                    <el-table-column label="主图标签" width="120" prop="Model"></el-table-column>
                    
                    
                    <el-table-column label="选择跳转" width="150" prop="AddDTime" ></el-table-column>
                    <el-table-column label="使用商户" width="150" prop="AddDTime" ></el-table-column>
                    <el-table-column label="使用商品" width="150" prop="AddDTime" ></el-table-column>
                    
                    <el-table-column label="状态" prop="TotalStock" width="80">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0">已下架</span>
                            <span v-if="scope.row.Status == 1">已上架</span>
                            <span v-if="scope.row.Stock <= 5">库存不足</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-button type="text"  @click="Edit(scope.row.ID)" >编辑</el-button>
                            <el-button v-if="scope.row.Status == 0" type="text"  @click="Enable(scope.row.ID,scope.$index)" >上移</el-button>
                            <el-button v-if="scope.row.Status == 1" type="text"  @click="Disable(scope.row.ID,scope.$index)" >下移</el-button>
                            <el-button type="text"   @click="Del(scope.row.ID)"  >删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>

  
</template>


<script >
import { ref } from "vue"
export default {
    data() {
        return {
            keyword: ref(""),
            activeposition: ref(""),
            enabled: ref(""),
            jumptype: ref(""),
            addstatus: ref(false),
        };
    },
    methods: {
        //搜索相关
        search() {
            console.log(this.keyword, this.activeposition, this.enabled, this.jumptype);
        },
        reset() {
            this.keyword = "";
            this.activeposition = "";
            this.enabled = "";
            this.jumptype = "";
        },
        add() {
            this.$router.push({path:"/productdetailadd"});
        },
        
    },
    
}
</script>

<style>

</style>